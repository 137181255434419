import React from "react"

export const NunitoFontImport: React.FC = () => {
  return (
    <link
      href="https://fonts.googleapis.com/css?family=Nunito:300,400,700,900&display=swap"
      rel="stylesheet"
    />
  )
}

export const MontserratFontImport: React.FC = () => {
  return (
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
      rel="stylesheet"
    />
  )
}
