import React from "react"

import { Global } from "@emotion/core"

const bp = "@media(min-width: 800px)"

export const SplitImage: React.FC<{
  image?: React.ReactNode
  breakpoint?: string
  background?: string
}> = ({ children, image, breakpoint = bp, background, ...props }) => {
  React.useLayoutEffect(() => {
    function updateHeight(this: Window) {
      const vh = this.innerHeight
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    updateHeight.call(window)

    window.addEventListener<"resize">("resize", updateHeight)

    return () => {
      window.removeEventListener("resize", updateHeight)
    }
  }, [])

  return (
    <div
      css={{
        minHeight: "100vh",
        display: "grid",
        [breakpoint]: {
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "100%",
          height: "var(--vh, 100vh)",
        },
      }}
      {...props}
    >
      <Global
        styles={{
          html: {
            [bp]: {
              // https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
              position: "fixed" as const,
              width: "100%",
              overflow: "hidden",
            },
          },
        }}
      />
      <div
        css={[
          {
            alignSelf: "center",
            // Leave space for CustomerChat
            paddingBottom: "64pt",
            [bp]: {
              alignSelf: "start",
              overflow: "auto",
              width: "100%",
              height: "100%",
            },
          },
        ]}
      >
        {children}
      </div>
      <figure
        css={{
          display: "none",
          backgroundColor: background ?? "inherit",
          [bp]: {
            display: "block",
          },
          ".gatsby-image-wrapper": {
            height: "100%",
            maxHeight: "100%",
          },
        }}
      >
        {image}
      </figure>
    </div>
  )
}
