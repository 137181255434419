import React, { Component } from "react"

import PropTypes from "prop-types"

// https://github.com/Yoctol/react-messenger-customer-chat
const removeElementByIds = (ids: string[]) => {
  ids.forEach(id => {
    const element = document.getElementById(id)
    if (element && element.parentNode) {
      element.parentNode.removeChild(element)
    }
  })
}

interface MessengerCustomerChatProps {
  pageId: string
  appId?: string
  shouldShowDialog?: boolean
  htmlRef?: string
  minimized?: boolean
  themeColor?: string
  loggedInGreeting?: string
  loggedOutGreeting?: string
  greetingDialogDisplay?: "show" | "hide" | "fade"
  greetingDialogDelay?: number
  autoLogAppEvents?: boolean
  xfbml?: boolean
  version?: string
  language?: string
  onCustomerChatDialogShow?: () => void
  onCustomerChatDialogHide?: () => void
}

export default class MessengerCustomerChat extends Component<MessengerCustomerChatProps> {
  static propTypes = {
    pageId: PropTypes.string.isRequired,
    appId: PropTypes.string,

    shouldShowDialog: PropTypes.bool,
    htmlRef: PropTypes.string,
    minimized: PropTypes.bool,
    themeColor: PropTypes.string,
    loggedInGreeting: PropTypes.string,
    loggedOutGreeting: PropTypes.string,
    greetingDialogDisplay: PropTypes.oneOf(["show", "hide", "fade"]),
    greetingDialogDelay: PropTypes.number,
    autoLogAppEvents: PropTypes.bool,
    xfbml: PropTypes.bool,
    version: PropTypes.string,
    language: PropTypes.string,
    onCustomerChatDialogShow: PropTypes.func,
    onCustomerChatDialogHide: PropTypes.func,
  }

  static defaultProps = {
    shouldShowDialog: false,
    htmlRef: undefined,
    minimized: undefined,
    themeColor: undefined,
    loggedInGreeting: undefined,
    loggedOutGreeting: undefined,
    greetingDialogDisplay: undefined,
    greetingDialogDelay: undefined,
    autoLogAppEvents: true,
    xfbml: true,
    version: "2.11",
    language: "en_US",
    onCustomerChatDialogShow: undefined,
    onCustomerChatDialogHide: undefined,
  }

  state = {
    fbLoaded: false,
    shouldShowDialog: undefined,
  }

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
  componentDidMount() {
    this.setFbAsyncInit()
    this.reloadSDKAsynchronously()
  }

  componentDidUpdate(prevProps: MessengerCustomerChatProps) {
    if (
      prevProps.pageId !== this.props.pageId ||
      prevProps.appId !== this.props.appId ||
      prevProps.shouldShowDialog !== this.props.shouldShowDialog ||
      prevProps.htmlRef !== this.props.htmlRef ||
      prevProps.minimized !== this.props.minimized ||
      prevProps.themeColor !== this.props.themeColor ||
      prevProps.loggedInGreeting !== this.props.loggedInGreeting ||
      prevProps.loggedOutGreeting !== this.props.loggedOutGreeting ||
      prevProps.greetingDialogDisplay !== this.props.greetingDialogDisplay ||
      prevProps.greetingDialogDelay !== this.props.greetingDialogDelay ||
      prevProps.autoLogAppEvents !== this.props.autoLogAppEvents ||
      prevProps.xfbml !== this.props.xfbml ||
      prevProps.version !== this.props.version ||
      prevProps.language !== this.props.language
    ) {
      this.setFbAsyncInit()
      this.reloadSDKAsynchronously()
    }
  }

  setFbAsyncInit() {
    const { appId, autoLogAppEvents, xfbml, version } = this.props
    const anyWindow = window as any
    anyWindow.fbAsyncInit = () => {
      anyWindow.FB.init({
        appId,
        autoLogAppEvents,
        xfbml,
        version: `v${version}`,
      })

      this.setState({ fbLoaded: true })
    }
  }

  loadSDKAsynchronously() {
    const { language } = this.props
    /* eslint-disable */
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0] as any
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s) as HTMLScriptElement
      js.id = id
      js.src = `https://connect.facebook.net/${language}/sdk/xfbml.customerchat.js`
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
    /* eslint-enable */
  }

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
  removeFacebookSDK() {
    removeElementByIds(["facebook-jssdk", "fb-root"])

    delete (window as any).FB
  }

  reloadSDKAsynchronously() {
    this.removeFacebookSDK()
    this.loadSDKAsynchronously()
  }

  controlPlugin() {
    const { shouldShowDialog } = this.props

    if (shouldShowDialog) {
      ;(window as any).FB.CustomerChat.showDialog()
    } else {
      ;(window as any).FB.CustomerChat.hideDialog()
    }
  }

  subscribeEvents() {
    const { onCustomerChatDialogShow, onCustomerChatDialogHide } = this.props

    if (onCustomerChatDialogShow) {
      ;(window as any).FB.Event.subscribe(
        "customerchat.dialogShow",
        onCustomerChatDialogShow
      )
    }

    if (onCustomerChatDialogHide) {
      ;(window as any).FB.Event.subscribe(
        "customerchat.dialogHide",
        onCustomerChatDialogHide
      )
    }
  }

  createMarkup() {
    const {
      pageId,
      htmlRef,
      minimized,
      themeColor,
      loggedInGreeting,
      loggedOutGreeting,
      greetingDialogDisplay,
      greetingDialogDelay,
    } = this.props

    const refAttribute = htmlRef !== undefined ? `ref="${htmlRef}"` : ""
    const minimizedAttribute =
      minimized !== undefined ? `minimized="${minimized}"` : ""
    const themeColorAttribute =
      themeColor !== undefined ? `theme_color="${themeColor}"` : ""
    const loggedInGreetingAttribute =
      loggedInGreeting !== undefined
        ? `logged_in_greeting="${loggedInGreeting}"`
        : ""
    const loggedOutGreetingAttribute =
      loggedOutGreeting !== undefined
        ? `logged_out_greeting="${loggedOutGreeting}"`
        : ""
    const greetingDialogDisplayAttribute =
      greetingDialogDisplay !== undefined
        ? `greeting_dialog_display="${greetingDialogDisplay}"`
        : ""
    const greetingDialogDelayAttribute =
      greetingDialogDelay !== undefined
        ? `greeting_dialog_delay="${greetingDialogDelay}"`
        : ""

    return {
      __html: `<div
        class="fb-customerchat"
        page_id="${pageId}"
        ${refAttribute}
        ${minimizedAttribute}
        ${themeColorAttribute}
        ${loggedInGreetingAttribute}
        ${loggedOutGreetingAttribute}
        ${greetingDialogDisplayAttribute}
        ${greetingDialogDelayAttribute}
      ></div>`,
    }
  }

  render() {
    const { fbLoaded, shouldShowDialog } = this.state

    if (fbLoaded && shouldShowDialog !== this.props.shouldShowDialog) {
      document.addEventListener(
        "DOMNodeInserted",
        event => {
          const element = event.target as HTMLElement
          if (
            element &&
            element.className &&
            typeof element.className === "string" &&
            element.className.includes("fb_dialog")
          ) {
            this.controlPlugin()
          }
        },
        false
      )
      this.subscribeEvents()
    }
    // Add a random key to rerender. Reference:
    // https://stackoverflow.com/questions/30242530/dangerouslysetinnerhtml-doesnt-update-during-render
    return <div key={Date()} dangerouslySetInnerHTML={this.createMarkup()} />
  }
}
