import React from "react"

import MessengerCustomerChat from "../components/MessengerCustomerChat"

const CustomerChat: React.FC = () => (
  <MessengerCustomerChat
    pageId="1481839328768653"
    themeColor="#ed5351"
    language="sv_SE"
    version="6.0"
  />
)

export default CustomerChat
